import { LineSegment } from "./Sketch";

export interface Point {
  x: Date;
  y: number;
}

export class ShapeFilterDefinition {
  public _points: Point[];
  public _lineSegments: LineSegment[];
  public angleError: number;
  public lineError: number;
  public actualValue: boolean;
  public actualDate: boolean;
  public lineLengths: number[];
  public lineSlopes: number[];
  public angles: number[];

  constructor(angleError:number, lineError:number) {
    this._points = [];
    this._lineSegments = [];
    this.angleError = angleError;
    this.lineError = lineError;
    this.actualValue = true;
    this.actualDate = true;
    this.lineLengths = [];
    this.lineSlopes = [];
    this.angles = [];
  }

  // used by ShapeFilterEditor
  GetPoints(): Point[] {
    return this._points;
  }

  GetLineSegments(): LineSegment[] {
    return this._lineSegments;
  }

  CalculateMetrics(): void {
    this.lineLengths = [];
    this.lineSlopes = [];
    this.angles = [];

    const points = this.GetPoints();

    for (let i = 0; i < points.length - 1; i++) {
      const current = points[i];
      const next = points[i + 1];

      const dx = next.x.getTime() - current.x.getTime();
      const dy = next.y - current.y;
      const length = Math.sqrt(dx * dx + dy * dy);
      this.lineLengths.push(length);

      // Calculate slope
      const slope = dy / dx;
      this.lineSlopes.push(slope);

      if (i < points.length - 2) {
        const nextNext = points[i + 2];
        const dx2 = nextNext.x.getTime() - next.x.getTime();
        const dy2 = nextNext.y - next.y;
        const length2 = Math.sqrt(dx2 * dx2 + dy2 * dy2);

        const angle = Math.acos((dx * dx2 + dy * dy2) / (length * length2));
        this.angles.push(angle);
      }
    }
  }
}
