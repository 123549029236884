
export function throwIfNull<T>(value: T | null | undefined, message?: string): T {
    if (value === null || value === undefined) {
        throw new Error(message || 'Value is null or undefined');
    }
    return value;
}

export class Point2D
{
    public constructor(x:number, y:number)
    {
        this.x = x;
        this.y = y;
    }

    public x:number;
    public y:number;
};

export class ShapeSearchReturnStruct
{
    public constructor(partitionName:string, score:number, matchedSegmentIds:number[])
    {
        this.partitionName = partitionName;
        this.score = score;
        this.matchedSegmentIds = matchedSegmentIds;
    }

    public partitionName:string;
    public score:number;
    public matchedSegmentIds:number[];
};
