import React, { useContext, useRef } from "react";
import "./Layout.css";
import ControlPanel from "./ControlPanel";
import CustomDialog from "./CustomDialog";
import ShapeFilterEditorWrapper from "./ShapeFilterEditorWrapper";
import TableauVizComponent from "./TableauVizComponent";
import { AppContext } from "./AppContext";
import ShapeLibrary from "./ShapeLibrary";
import { ReactComponent as TableauLogo } from "../Tableau_From_Salesforce_Logo_Color.svg"
import Chat from "./Chat";
import CustomTabs from "./CustomTabs";
import { SketchRef } from "./Sketch";

const Layout: React.FC = () => {
	const [dialogOpen, setDialogOpen] = React.useState(false);
	const [currentTab, setCurrentTab] = React.useState(0);
	const sketchRef = useRef<SketchRef>(null);
	const context = useContext(AppContext);

	if (!context) {
		return null; // or throw an error
	}

	const {
		currentShapeIndex,
		canvasWidth,
		canvasHeight,
		dataPartitions,
		queryShape,
		updateLibraryForClearButton,
		removeShapeFilterFromLibrary,
		currentMeasure,
		defaultMeasure,
		currentColor,
		setCurrentColor,
		updateCurrentMeasure,
		listOfMeasures
	} = context;


	const handleClearButton = () => {
		sketchRef.current?.clearCanvas();
		updateLibraryForClearButton(currentShapeIndex);
	};

	const handleCancelButton = () => {
		removeShapeFilterFromLibrary(currentShapeIndex);
		setDialogOpen(false);
	};

	const handleSaveButton = () => {
		setDialogOpen(false);
		queryShape(currentShapeIndex);
	};

	let measureButtons: JSX.Element[] = [];
	listOfMeasures.forEach(measure =>
		measureButtons.push(
			<button
				className="measure-button"
				key={measure.measureName}
				onClick={() => {
					setCurrentColor(measure.color);
					updateCurrentMeasure(measure.measureName);
				}}
			>
				<span className="measure-button-swatch-holder">
					<span
						className={`measure-button-swatch ${measure.measureName === defaultMeasure.measureName ? 'measure-button-swatch-blue' : 'measure-button-swatch-orange'} ${currentMeasure === measure.measureName && currentColor === measure.color ? 'selected' : ''}`}
					></span>
				</span>
				<div className="measure-button-text-wrapper">
					<span className={`measure-button-text ${currentMeasure === measure.measureName && currentColor === measure.color ? 'selected' : ''}`}>
						{measure.measureName === 'Customer CSAT' ? 'Customer Satisfaction' : measure.measureName}
					</span>
				</div>
			</button>

		)
	);

	return (
		<div className="container">
			<div className="header">
				<div className="title-wrapper">
					<TableauLogo />
				</div>
				{dataPartitions.length === 0 && <ControlPanel />}
			</div>
			<div className="main-content">
				<div className="viz-wrapper">
					<TableauVizComponent
						url={"https://10ay.online.tableau.com/#/site/onlinerocks/views/Fake_GAI_Usage_2024_jobs_not_names/DailyGAI"}
						filterField={"Name"}
						sheetName={"1. Daily Generative AI Usage"}
					/>
				</div>
				<div className="sidebar">
					<CustomTabs onClick={(tabNumber: number) => setCurrentTab(tabNumber)} currentTab={currentTab} />
					{currentTab === 0 && <ShapeLibrary setDialogOpen={setDialogOpen} activeSketchRef={sketchRef} />}
					{currentTab === 1 && <Chat />}
				</div>
			</div>
			<CustomDialog
				open={dialogOpen}
				onRequestClear={handleClearButton}
				onRequestCancel={handleCancelButton}
				onRequestSave={handleSaveButton}
				children={
					<div className="shape-editor-wrapper">
						<div className="shape-editor-options-description">Choose a measure color to sketch a shape or an annotation color to add notes.</div>
						<ShapeFilterEditorWrapper
							sketchRef={sketchRef}
							shapeFilterIndex={currentShapeIndex}
							isViewOnly={false}
							canvasWidth={canvasWidth}
							canvasHeight={canvasHeight}
						/>
						<div className="shape-editor-options">
							<div className="measure-options">
								<div className="measure-colors-description"><b>Measures</b></div>
								<div className="measure-buttons-wrapper">
									{measureButtons}
								</div>
							</div>


							<div className="annotation-options">
								<div className="annotation-colors-description"><b>Annotation Palette</b></div>
								<div className="annotation-colors-wrapper">
									<button
										className={`annotation-color annotation-color-black ${currentColor === '#636363' ? 'selected' : ''}`}
										onClick={() => setCurrentColor('#636363')}
									></button>
									<button
										className={`annotation-color annotation-color-green ${currentColor === '#669B59' ? 'selected' : ''}`}
										onClick={() => setCurrentColor('#669B59')}
									></button>
									<button
										className={`annotation-color annotation-color-purple ${currentColor === '#7a599b' ? 'selected' : ''}`}
										onClick={() => setCurrentColor('#7a599b')}
									></button>
								</div>
							</div>
						</div>
					</div>
				}
			/>
		</div>
	);
};

export default Layout;
