import React, { useEffect, useRef, useState } from "react";
import { ChatText, ChatTextType } from "./ChatText";

import { ReactComponent as EinsteinEntryLogo } from "../EinsteinEntry_Logo.svg";
import { ReactComponent as EinsteinMessageLogo } from "../EinsteinMessage_Logo.svg";
import { ReactComponent as EinsteinUserMessage } from "../EinsteinUserMessage_Logo.svg";
import { ReactComponent as SendMessageIcon } from "../SendMessage_Icon.svg";

import "./Chat.css";

type MessageListType = {
  original: ChatTextType[],
  displayed: ChatTextType[]
};

const Chat: React.FC = () => {
  const [showIntro, setShowIntro] = useState(true);
  const [messageLists, setMessageLists] = useState<MessageListType>({original: [...ChatText], displayed: []});
  const [waitingForUser, setWaitingForUser] = useState(true);

  const scrollItemRef = useRef<HTMLDivElement>(null);

  const displayNextMessage = () => {
    const nextMessage = messageLists.original[0];
    setMessageLists({ original: [...messageLists.original.slice(1)], displayed: [...messageLists.displayed, nextMessage]});
    if (nextMessage.waitForUser) {
      setWaitingForUser(true);
    }
  }

  useEffect(() => {
    if (!waitingForUser && messageLists.original.length > 0) {
      let timeout = 1000;
      // messages from the user shoul appear fast
      if (messageLists.original[0].isUserLogo) {
        timeout = 300;
      }
      // if the last message was from a user, Agent message should take longer to appear
      if (messageLists.displayed.length > 0 && messageLists.displayed[messageLists.displayed.length - 1].isUserLogo) {
        timeout = 1500;
      }
      // wait and then display next message
      setTimeout(() => {
        displayNextMessage();
      }, timeout);
    }
  }, [waitingForUser, messageLists.original]);

  useEffect(() => {
        // scroll to bottom of message list
        if (scrollItemRef.current) {
          scrollItemRef.current.scrollIntoView({behavior: "smooth", block: "end"});
        }
  }, [messageLists.displayed])
  
  const initialPage: JSX.Element = (
    <div className="intro-page-wrapper">
      <div className="intro-logo">
        <EinsteinEntryLogo />
      </div>
      <div className="intro-header">You are about to use Tableau Agent</div>
      <p>Tableau Agent can help provide insights into your visualization.</p>
      <p>
        Generative AI can produce inaccurate or harmful responses. Review output
        for accuracy and safety. You assume responsibility for how the outcomes
        of Tableau Agent are applied to your organization.
        <a className="intro-link" href="tableau.com">Learn more</a>
      </p>
      <button
        className="intro-button low-density"
        tabIndex={0}
        onClick={() => setShowIntro(false)}
      >
        Got It
      </button>
    </div>
  );

  let createChatMessage = (item: any): JSX.Element => (
    <div className={`message-item einstein-message  ${item.includeLogo ? "" : "message-item-compact"}`} key={item.text.split(' ')[0]}>
      <div className={`message-logo ${item.includeLogo ? "" : "hidden-logo" }`}>
        {item.isUserLogo ? <EinsteinUserMessage /> : <EinsteinMessageLogo />}
      </div>
      <p>{item.text}</p>
    </div>
  );

  const [messageText, setMessageText] = useState('');
  const chatBox: JSX.Element = (
    <div className="message-input-wrapper">
      <div className="message-input-text-wrapper" id="message-input-text-wrapper">
        <textarea
          maxLength={1000}
          placeholder="Describe your task or ask a question..."
          className="message-input-text-box"
          value={messageText}
          onChange={(e) => setMessageText(e.target.value)}
        ></textarea>
        <div
          aria-live="off"
          className="message"
          id="tableau-ui-1731012068934"
        ></div>
      </div>
      <button className="send-button" onClick={() => {
          setWaitingForUser(false);
          setMessageText('');
        }}>
        <SendMessageIcon />
      </button>
    </div>
  );

  const chatPane: JSX.Element = (
    <div className="message-area">
      <div className="message-list-blank-space"></div>
      <div className="message-list-wrapper">
        <div className="message-list">
          {messageLists.displayed.map(message => createChatMessage(message))}
          <div ref={scrollItemRef}></div>
        </div>
      </div>
      {chatBox}
    </div>
  );

  return (
    <div className="tableau-agent">{showIntro ? initialPage : chatPane}</div>
  );
};

export default Chat;
