import React, { useContext } from 'react';
import { AppContext } from './AppContext';
import { Sketch, SketchRef } from './Sketch';

type ShapeFilterEditorWrapperProps = {
  shapeFilterIndex: number;
  isViewOnly: boolean;
  canvasWidth: number;
  canvasHeight: number;
  sketchRef?: React.RefObject<SketchRef>;
}

const ShapeFilterEditorWrapper: React.FC<ShapeFilterEditorWrapperProps> = ({shapeFilterIndex, isViewOnly, canvasWidth, canvasHeight, sketchRef}) => {
    const context = useContext(AppContext);

    if (!context) {
      return null; // or throw an error
    }
  
    /*const {
      forecastingEnabled,
      valueRange,
      dateRange,
      shapeFilterLibrary
      dataTable,
    } = context;*/
    
    return (
      <div style={{width: '100%', height: '100%'}}>
        {/*<ShapeFilterEditor
            shapeFilterIndex={shapeFilterIndex}
            valueRange={valueRange}
            dateRange={dateRange}
            isViewOnly={isViewOnly}
            forecastingEnabled={forecastingEnabled}
            measure={currentMeasure}
            listOfMeasures={listOfMeasures}
        />*/}
        <Sketch 
          ref={sketchRef} 
          shapeFilterIndex={shapeFilterIndex} 
          isViewOnly={isViewOnly} 
          canvasWidth={canvasWidth} 
          canvasHeight={canvasHeight}/>
      </div>
    );
};

export default ShapeFilterEditorWrapper;

