import React from 'react';

export type CustomDropdownOptionType = {
  value: string;
  label?: string;
  showDot?: boolean
};

interface CustomDropdownProps {
  label?: string;
  options: CustomDropdownOptionType[];
  onChange: (value: string) => void;
}

const CustomDropdown: React.FC<CustomDropdownProps> = ({ label, options, onChange }) => {
  return (
    <div className="dropdown-container">
      {label && <label>{label}</label>}
      <select onChange={(e) => onChange(e.target.value)}>
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}{option.showDot ? '• ' : ''}
          </option>
        ))}
      </select>
    </div>
  );
};

export default CustomDropdown;
