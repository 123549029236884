declare global {
  interface Window {
    gapi: any;
  }
}

type RowInfo = {
  name: string,
  quarter: string,
  count: number
}

type GetSpreadsheetRowsResult = {
  majorDimension: string;
  range: string;
  values: string[]; // name, quarter of year, count
}

export class GoogleSheetsManager {
  // GCP API OAuth 2.0 Client ID, created by Diana via Embark GCP Sandbox. Project 63242072115/ehc-dianawang-68ee2c
  // Expires beginning of April 2025
  private CLIENT_ID = '63242072115-aei06urtmhovjqfecgi5e7mmu1qgoeog.apps.googleusercontent.com';

  // Authorization scopes required by the API; multiple scopes can be
  // included, separated by spaces.
  private SCOPE = 'https://www.googleapis.com/auth/spreadsheets';

  /**
   * Line linearization spreadsheet - https://docs.google.com/spreadsheets/d/{spreadsheetId}/
   */
  private spreadsheetId = '1kLvr3OC_95CIeHwQUTcfR93Sm4quJ0aL8kOCzmP-FKE';

  private client: any;

  constructor() { 
    this.authenticateUser();
  }

  private setAccessToken(accessToken: string) {
    document.cookie = `access_token=${accessToken}`;
  }

  private setTokenExpirationTime(expirationTime: number) {
    document.cookie = `access_token_expiration_time=${expirationTime}`;
  }

  private getAccessToken() {
    return this.getCookie("access_token");
  }

  private getTokenExpirationTime() {
    const expirationTimeString = this.getCookie("access_token_expiration_time") ;
    return expirationTimeString ? parseFloat(expirationTimeString) : 0;
  }

  private getCookie(cookieName: string) {
    return document.cookie
      .split("; ")
      .find((row) => row.startsWith(`${cookieName}=`))
      ?.split("=")[1];
  }

  /**
   * returns whether access token is valid
   */
  private isTokenValid(): boolean {
      return !!this.getAccessToken() && !!this.getTokenExpirationTime() && Date.now() < this.getTokenExpirationTime();
  }

  /**
   * Validate that user is authenticated and if not, authenticate them by asking them to log in via an OAuth consent screen popup
   */
  private async authenticateUser(): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      if (this.isTokenValid()) {
        resolve();
        return;
      }

      if (!this.client) {
          await this.loadGoogleAccountsScript();
          this.initializeTokenClient();
      }
      
      try {
          if (this.getAccessToken()) {
            // Skip display of account chooser and consent dialog for an existing session.
            this.client.requestAccessToken({ prompt: '' });
            resolve();
          } else {
            // Prompt the user to select a Google Account and ask for consent to share their data
            // when establishing a new session.
            this.client.requestAccessToken({ prompt: 'consent' });
            resolve();
          }
      } catch (err) {
          console.error(err);
          reject(new Error('Error during authentication'));
      }
    });
};

/**
 * load Google Identity Services script for authentication
 */
  private loadGoogleAccountsScript(): Promise<void> {
      return new Promise((resolve, reject) => {
          const script = document.createElement('script');
          script.src = 'https://accounts.google.com/gsi/client';
          script.onload = () => resolve();
          script.onerror = () => reject(new Error('Failed to load Google Accounts script'));
          document.body.appendChild(script);
      });
  }

  /**
   * fetch access token and its expiration date and save to browser cookie
   */
  private initializeTokenClient = () => {
      this.client = window.google.accounts.oauth2.initTokenClient({
          client_id: this.CLIENT_ID,
          scope: this.SCOPE,
          callback: (response: any) => {
              if (response.error !== undefined) {
                  throw (response);
              }
              this.setAccessToken(response.access_token);
              this.setTokenExpirationTime(Date.now() + response.expires_in * 1000);
          },
      });
  }

  /**
   * Clears all the rows in the spreadsheet
   */
  public clearRows() {
    return new Promise<void>((resolve, reject) => {
      this.authenticateUser().then(() => {
        var xhr = new XMLHttpRequest();
        xhr.open('POST', `https://sheets.googleapis.com/v4/spreadsheets/${this.spreadsheetId}/values:batchClear`);
        xhr.setRequestHeader('Authorization', 'Bearer ' + this.getAccessToken());
        xhr.onreadystatechange = function() {
          if (xhr.readyState === XMLHttpRequest.DONE) {
              resolve();
          }
        };
        const requestBody = {
          ranges: ['Sheet1!A2:A', 'Sheet1!B2:B', 'Sheet1!C2:C']
        };
        xhr.send(JSON.stringify(requestBody));
      });
    });
  }

  /**
   * Fetches all the data from the spreadsheet
   */
  public listRows() {
    return new Promise<RowInfo[]>((resolve, reject) => {
      this.authenticateUser().then(() => {
        var range = 'Sheet1';
        var xhr = new XMLHttpRequest();
        xhr.open('GET', `https://sheets.googleapis.com/v4/spreadsheets/${this.spreadsheetId}/values/${range}`);
        xhr.setRequestHeader('Authorization', 'Bearer ' + this.getAccessToken());
        xhr.onreadystatechange = function() {
          if (xhr.readyState === XMLHttpRequest.DONE && this.status === 200) {
              const result: GetSpreadsheetRowsResult = JSON.parse(xhr.response);
              const rowInfos: RowInfo[] = result.values.map(value => {
                return {
                  name: value[0],
                  quarter: value[1],
                  count: Number(value[2])
                };
              });
              resolve(rowInfos);
          }
        };
        xhr.send();
      });
    });
  }

  /**
   * Update google sheet with values to draw the line linearizations
   * @param newValues new data
   * @param clearRows if true, clears spreadsheet of all existing rows (except headers) prior to adding new data. If false, appends new data to sheet
   */
  public async updateSheet(newValues: (string|number)[][], clearRows: boolean = true): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (newValues.length > 0) {
        const range = 'Sheet1!A1:C3';
        var xhr = new XMLHttpRequest();
        xhr.open('POST', `https://sheets.googleapis.com/v4/spreadsheets/${this.spreadsheetId}/values/${range}:append?valueInputOption=USER_ENTERED`);
        xhr.setRequestHeader('Authorization', 'Bearer ' + this.getAccessToken());
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.onreadystatechange = function() {
            resolve();
        };

        if(clearRows) {
          // clearing table and adding new data
          this.clearRows().then(() => {
            const requestBody = {
              values: newValues
            };
            xhr.send(JSON.stringify(requestBody));
          })
        } else {
          alert("Don't think we want to be here")
          // appending data to table
          this.listRows().then(existingRows => {
            // filter out rows that already exist in the table.
            const filteredValues = newValues.filter(value => 
              !existingRows.some(row => value[0] === row.name && value[1] === row.quarter && value[2] === row.count)
            );

            if (filteredValues.length > 0) {
                const requestBody = {
                  values: newValues
                };
                xhr.send(JSON.stringify(requestBody));
            } else {
              console.log("all new values already in spreadsheet");
              resolve();
            }
          });
        }
      } else {
        console.log('Not updating spreadsheet. Empty new values list');
        resolve();
      }
    });
  }
}