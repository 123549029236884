import React, { useContext } from 'react';
import CustomDropdown from './CustomDropDown'
import './ControlPanel.css';
import { AppContext } from './AppContext';


const ControlPanel: React.FC = () => {

    const context = useContext(AppContext);

    if (!context) {
        return null; // or throw an error
    }

    const {
        searchByPercentage,
        setSearchByPercentage,
        epsilons,
        epsilonIndex,
        setEpsilonIndex,
        loadNewDataset,
    } = context;

    const dataSourceOptions = [
        { value: '', label: '', showDot: false },
        { value: 'babynames', label: 'Employee GAI Usage', showDot: false },
    ];

    /*const cannedShapeOptions = [
        { value: '', label: '', showDot: false },
        { value: 'Dennis', label: 'Dennis', showDot: false },
        { value: 'Lucy', label: 'Lucy', showDot: false },
    ];*/

    // const measureOptions = [
    //     { value: 'Count', label: 'Count', showDot: true },
    // ];

    return (
        <div className="control-panel">
            <div className="control">
                <label>
                    <input type="checkbox" checked={searchByPercentage} onChange={(e) => setSearchByPercentage(e.target.checked)} />
                    Match Percentages
                </label>
            </div>
            <div className="control">
                <label>Resolution</label>
                <input type="range" min={0} max={epsilons.length-1} step={1} value={epsilonIndex}  onChange={(e) => setEpsilonIndex(Number(e.target.value))} />
            </div>

            <CustomDropdown label="Data source" options={dataSourceOptions} onChange={loadNewDataset} />
        </div>
    );
};

export default ControlPanel;

