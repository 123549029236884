import Layout from './components/Layout';
import { AppProvider } from './components/AppContext';

const App: React.FC = () => {
  return (
    <AppProvider>
      <div className="App">
        <Layout />
      </div>
    </AppProvider>
  );
}

export default App;
