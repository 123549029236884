import { useEffect, useRef, useContext, useImperativeHandle, forwardRef } from "react";
import { AppContext } from './AppContext';
import { ShapeFilterDefinition } from './ShapeFilterDefinition';
import { Point2D, throwIfNull } from "./utils";

// Define the type of methods to expose to the parent via ref
export interface SketchRef {
    clearCanvas: () => void;
}

class MouseAndTouchEventParameters {
    canvasElement: HTMLCanvasElement;
    x: number;
    y: number;

    constructor(canvasElement: HTMLCanvasElement, x: number, y: number) {
        this.canvasElement = canvasElement;
        this.x = x;
        this.y = y;
    }

    public ConvertClientToOffsetCoordinates(): MouseAndTouchEventParameters {
        const rect = this.canvasElement.getBoundingClientRect();
    
        this.x -= rect.left + window.scrollX;
        this.y -= rect.top + window.scrollY;
    
        // Flip the y-axis
        this.y = this.canvasElement.height - this.y;
    
        return this;
    }
}



let xStart: number, xEnd: number, yStart: number, yEnd: number;
let paint = false;
let ctx: CanvasRenderingContext2D | null = null;
let lineThickness: number;
let currentSketchId = 0;

const drawingAreaWidthPaddingPercent = 0.15;
const drawingAreaHeightPaddingPercent = 0.15;

// LineSegment interface to store normalized coordinates
export interface LineSegment {
    xStart: number; // normalized to canvas width
    yStart: number; // normalized to canvas height
    xEnd: number;   // normalized to canvas width
    yEnd: number;   // normalized to canvas height
    thickness: number;
    color: string;
    sketchLineId: number;
}

// Function to normalize a point by the canvas dimensions
export function normalizePoint(x: number, y: number, canvasWidth: number, canvasHeight: number) {
    const widthPadding = drawingAreaWidthPaddingPercent * canvasWidth;
    const heightPadding = drawingAreaHeightPaddingPercent * canvasHeight;
    const drawingWidth = canvasWidth - 2*widthPadding;
    const drawingHeight = canvasHeight - 2*heightPadding;

    return {
        x: (x - widthPadding) / drawingWidth,
        y: (y - heightPadding) / drawingHeight,
    };
}

// Function to denormalize a point based on the current canvas size
export function denormalizePoint(x: number, y: number, canvasWidth: number, canvasHeight: number) {
    const widthPadding = drawingAreaWidthPaddingPercent * canvasWidth;
    const heightPadding = drawingAreaHeightPaddingPercent * canvasHeight;
    const drawingWidth = canvasWidth - 2*widthPadding;
    const drawingHeight = canvasHeight - 2*heightPadding;
    
    return {
        x: (x * drawingWidth) + widthPadding,
        y: (y * drawingHeight) + heightPadding,
    };
}

type SketchProps = {
    shapeFilterIndex: number;
    isViewOnly: boolean;
    canvasWidth: number,
    canvasHeight: number,
}

export const Sketch = forwardRef<SketchRef, SketchProps>(({shapeFilterIndex, isViewOnly, canvasWidth, canvasHeight}, ref) => {
    const { shapeFilterLibrary, updateShapeInLibrary, updateAnnotationInLibrary, currentColor, currentMeasure, listOfMeasures} = throwIfNull(useContext(AppContext));
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const linearizeButtonRef = useRef<HTMLButtonElement | null>(null);
    const shapeFilterLibraryRef = useRef(shapeFilterLibrary);
    const currentColorRef = useRef(currentColor);
    const currentMeasureRef = useRef(currentMeasure);
    const shapeFilterIndexRef = useRef(shapeFilterIndex);
    const updateShapeInLibraryRef = useRef(updateShapeInLibrary);
    const updateAnnotationInLibraryRef = useRef(updateAnnotationInLibrary);

    // Sync the ref with the current shapeFilterLibraryRef.current
    useEffect(() => {
        shapeFilterLibraryRef.current = shapeFilterLibrary;
    }, [shapeFilterLibrary]);

    // Sync the ref with the current currentColorRef.currentRef.current
    useEffect(() => {
        currentColorRef.current = currentColor;
    }, [currentColor]);

    // Sync the ref with the current currentMeasureRef.currentRef.current
    useEffect(() => {
        currentMeasureRef.current = currentMeasure;
    }, [currentMeasure]);

    // Sync the ref with the current shapeFilterLibraryRef.current
    useEffect(() => {
        updateShapeInLibraryRef.current = updateShapeInLibrary;
    }, [updateShapeInLibrary]);

    // Sync the ref with the current currentColorRef.currentRef.current
    useEffect(() => {
        updateAnnotationInLibraryRef.current = updateAnnotationInLibrary;
    }, [updateAnnotationInLibrary]);

    // Ref to track added event listeners
    const listenersRef = useRef<any>({
        mousedown: null,
        mouseup: null,
        mousemove: null,
        mouseLeave: null,
        touchstart: null,
        touchmove: null,
        touchend: null,
    });

    useEffect(() => {
        const canvas = canvasRef.current;
        
        // Helper function to add an event listener and track it
        const addListener = (eventType: any, handleEvent: any) => {
            const canvas = canvasRef.current as HTMLCanvasElement;

            if (listenersRef.current[eventType]) return; // Prevent duplicate listeners
            listenersRef.current[eventType] = handleEvent;
            canvas.addEventListener(eventType, handleEvent);
        };
    
        const removeListener = (eventType: string) => {
            const canvas = canvasRef.current as HTMLCanvasElement;

            if (listenersRef.current[eventType]) {
                canvas.removeEventListener(eventType, listenersRef.current[eventType]);
                listenersRef.current[eventType] = null;
            }
        };

        if (canvas) {
            // Initialize canvas context
            ctx = canvas.getContext("2d");
            if (ctx) {
                ctx.lineJoin = "round";
                ctx.lineCap = "round";
            } else {
                console.error("Canvas context is not available.");
            }

            if (!isViewOnly) {
                // Bind each event separately to avoid TypeScript type issues
                addListener("mousedown", handleMouseEvent);
                addListener("mousemove", handleMouseEvent);
                addListener("mouseup", handleMouseEvent);
                addListener("mouseleave", handleMouseEvent);
                addListener("touchstart", handleTouchEvent);
                addListener("touchmove", handleTouchEvent);
                addListener("touchend", handleTouchEvent);
            }
        } else {
            if (!isViewOnly) {
                // Bind each event separately to avoid TypeScript type issues
                removeListener("mousedown");
                removeListener("mousemove");
                removeListener("mouseup");
                removeListener("mouseleave");
                removeListener("touchstart");
                removeListener("touchmove");
                removeListener("touchend");
            }
        }

        // Handle different events with type-safe methods
        function handleMouseEvent(e: MouseEvent) {    
            if (!ctx) return;
    
            switch (e.type) {
                case "mousedown":
                    startDrawing(new MouseAndTouchEventParameters(e.currentTarget as HTMLCanvasElement,
                                                                  e.offsetX,
                                                                  e.offsetY));
                    break;
                case "mousemove":
                    if (paint) {
                        updateDrawing(new MouseAndTouchEventParameters(e.currentTarget as HTMLCanvasElement,
                                                                       e.offsetX,
                                                                       e.offsetY));
                    }
                    break;
                case "mouseup":
                case "mouseleave":
                    paint = false;
                    currentSketchId++; // move on to next sketch.
                    break;
            }
        }
    
        // Handle different events with type-safe methods
        function handleTouchEvent(e: TouchEvent) {    
            if (!ctx) return;
    
            // Touch events don't have 'offsetX' and 'offsetY' like mouse events, so we have to do the math ourselves (below).
            switch (e.type) {
                case "touchstart":
                    e.preventDefault();
                    startDrawing(new MouseAndTouchEventParameters(e.currentTarget as HTMLCanvasElement,
                                                                    e.touches[0].clientX,
                                                                    e.touches[0].clientY).ConvertClientToOffsetCoordinates());
                    break;
                case "touchmove":
                    if (paint) {
                        updateDrawing(new MouseAndTouchEventParameters(e.currentTarget as HTMLCanvasElement,
                                                                        e.touches[0].clientX,
                                                                        e.touches[0].clientY).ConvertClientToOffsetCoordinates());
                    }
                    break;
                case "touchend":
                    paint = false;
                    currentSketchId++; // move on to next sketch.
                    break;
            }
        }

        // Start drawing for mouse events, storing normalized coordinates
        function startDrawing(params: MouseAndTouchEventParameters) {
            const { x, y } = normalizePoint(params.x, params.y, params.canvasElement.width, params.canvasElement.height);
            xStart = x;
            yStart = y;
            xEnd = x;
            yEnd = y;
            paint = true;
            updateLineSegments("mousedown", params.canvasElement);
        }

        // Update drawing for mouse move events with normalized coordinates
        function updateDrawing(params: MouseAndTouchEventParameters) {
            const { x, y } = normalizePoint(params.x, params.y, params.canvasElement.width, params.canvasElement.height);
            xEnd = x;
            yEnd = y;

            if (ctx) ctx.lineWidth = lineThickness;
            updateLineSegments("mousemove", params.canvasElement);
        }

        // Draw function with denormalization to render points on the canvas
        function updateLineSegments(event: string, canvas: HTMLCanvasElement) {
            if (!ctx) return;

            // Add the new segment to the array (stored in normalized format)
            const lineSegment = {
                xStart,
                yStart,
                xEnd,
                yEnd,
                thickness: lineThickness,
                color: currentColorRef.current,
                sketchLineId: currentSketchId
            };

            // update library
            if (isDrawingMeasure(currentColorRef.current)) {
                let currentShapeFilter:ShapeFilterDefinition = shapeFilterLibraryRef.current[shapeFilterIndexRef.current].measureShapes.get(currentMeasureRef.current) ?? new ShapeFilterDefinition(25, 0.35);
                currentShapeFilter._lineSegments = [...currentShapeFilter._lineSegments, lineSegment];
                updateShapeInLibraryRef.current(currentShapeFilter, shapeFilterIndexRef.current, currentMeasureRef.current);
            } else {
                let currentShapeFilter:ShapeFilterDefinition = shapeFilterLibraryRef.current[shapeFilterIndexRef.current].annotationShapes.get(currentColorRef.current) ?? new ShapeFilterDefinition(25, 0.35);
                currentShapeFilter._lineSegments = [...currentShapeFilter._lineSegments, lineSegment];
                updateAnnotationInLibraryRef.current(currentShapeFilter, shapeFilterIndexRef.current, currentColorRef.current);
            }

            // Update start points for the next segment
            xStart = xEnd;
            yStart = yEnd;
        }
        const isDrawingMeasure = (color: string) => {
            return listOfMeasures.find(measure => measure.color === color);
        }
    }, [shapeFilterLibraryRef.current, shapeFilterIndexRef.current, currentColorRef.current, currentMeasureRef.current]);

    useEffect(() =>  {
        const draw = () => {
            const canvas = canvasRef.current as HTMLCanvasElement;
            if (canvas) {
                const ctx = canvas.getContext("2d");
                if (!ctx) {
                    console.error("Canvas context is not available.");
                    return;
                }

                // clear canvas to start from scratch
                ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
                // draw four circles at the corners of the active area.
                if (ctx.canvas.width > 500) {
                    const offsetX = canvas.width * drawingAreaWidthPaddingPercent;
                    const offsetY = canvas.height * drawingAreaHeightPaddingPercent;

                    const drawCircle = (x: number, y: number, radius: number, color: string) => {
                        ctx.beginPath();
                        ctx.arc(x, y, radius, 0, Math.PI * 2);
                        ctx.fillStyle = color;
                        ctx.fill();
                    };

                    const circleRadius = 2;
                    const circleColor = "rgba(211, 211, 211, 0.8)";
                    // Top-left corner
                    drawCircle(offsetX, offsetY, circleRadius, circleColor);
                    // Top-right corner
                    drawCircle(canvas.width - offsetX, offsetY, circleRadius, circleColor);
                    // Bottom-left corner
                    drawCircle(offsetX, canvas.height - offsetY, circleRadius, circleColor);
                    // Bottom-right corner
                    drawCircle(canvas.width - offsetX, canvas.height - offsetY, circleRadius, circleColor);
                }

                const drawLine = (line: LineSegment) => {
                    // Denormalize coordinates for actual drawing
                    const startPoint = denormalizePoint(line.xStart, line.yStart, canvas.width, canvas.height);
                    const endPoint = denormalizePoint(line.xEnd, line.yEnd, canvas.width, canvas.height);

                    if (!isViewOnly) {
                        line.thickness = 5;
                    } else {
                        line.thickness = 2;
                    }
                    // Draw the line
                    ctx.beginPath();
                    ctx.moveTo(startPoint.x, startPoint.y);
                    ctx.lineTo(endPoint.x, endPoint.y);
                    ctx.lineWidth = line.thickness;
                    ctx.strokeStyle = line.color
                    ctx.stroke();
                };

                shapeFilterLibraryRef.current[shapeFilterIndexRef.current].measureShapes.forEach(shape => {
                    shape.GetLineSegments().forEach(lineSegment => drawLine(lineSegment));
                });
                shapeFilterLibraryRef.current[shapeFilterIndexRef.current].annotationShapes.forEach(shape => {
                    shape.GetLineSegments().forEach(lineSegment => drawLine(lineSegment));
                });
            }
        }

        draw();
    }, [shapeFilterLibraryRef.current, shapeFilterIndexRef.current]);

    useEffect(() => {
        if (linearizeButtonRef) {
            linearizeButtonRef.current?.addEventListener("click", handleLinearizeButtonClick)
        }
    }, [])
    
    // Add event listener for the Linearize button
    const handleLinearizeButtonClick = () => {
        console.log("Linearize button clicked."); // Debug statement to confirm button click
        let measureShapes = shapeFilterLibraryRef.current[shapeFilterIndexRef.current].measureShapes;
        measureShapes.forEach((shape: ShapeFilterDefinition) => {
            const linearizationPoints = linearizeSketches(shape._lineSegments);
            console.log(linearizationPoints);
            drawLinearization(linearizationPoints);
        })
    }

    function drawLinearization(segments: Point2D[][]) {
        const canvas = canvasRef.current as HTMLCanvasElement;
        if (canvas) {
            const ctx = canvas.getContext("2d");
            if (!ctx) {
                console.error("Canvas context is not available.");
                return;
            }

            console.log('segments');
            console.log(segments);

            segments.forEach(segmentPoints => {
                const denormalizedPoints = segmentPoints.map(point => {
                    return denormalizePoint(point.x, point.y, canvas.width, canvas.height);
                });

                // Draw simplified line overlay
                ctx!.lineWidth = 10; 
                ctx!.strokeStyle = "rgba(173, 216, 230, 0.7)"; 

                ctx!.beginPath();
                ctx!.moveTo(denormalizedPoints[0].x, denormalizedPoints[0].y);
                for (let i = 1; i < denormalizedPoints.length; i++) {
                    ctx!.lineTo(denormalizedPoints[i].x, denormalizedPoints[i].y);
                }
                ctx!.stroke();
            })

            // Restore the original sketch color
            ctx.strokeStyle = currentColorRef.current ?? "#000000";
        }
    }


    // Function to clear the canvas
    const clearCanvas = () => {
        if (!ctx) {
            console.error("Canvas context is not available.");
            return;
        }

        // Clear the canvas
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    }

    // Use useImperativeHandle to expose clearCanvas to the parent component
    useImperativeHandle(ref, () => ({
        clearCanvas,
    }));

    return (
        <div className="sketch-canvas">
            <canvas ref={canvasRef} id="canvas" width={canvasWidth} height={canvasHeight}></canvas>
            {/*<div id="sketch-controls">*/}
                {/*{!isViewOnly && <button id="linearizeButton" ref={linearizeButtonRef}>Linearize</button>}*/}
                {/*<input type="color" id="colorPicker" value="#006666" onInput={handleColorPickerInput} />*/}
            {/*</div>*/}
        </div>
    );
});

function douglasPeucker(points: Point2D[], tolerance: number): Point2D[] {
    if (points.length < 3) return points;

    const start = points[0];
    const end = points[points.length - 1];
    let maxDistance = 0;
    let index = 0;

    for (let i = 1; i < points.length - 1; i++) {
        const distance = perpendicularDistance(points[i], start, end);
        if (distance > maxDistance) {
            index = i;
            maxDistance = distance;
        }
    }

    if (maxDistance > tolerance) {
        const left = douglasPeucker(points.slice(0, index + 1), tolerance);
        const right = douglasPeucker(points.slice(index), tolerance);
        return [...left.slice(0, -1), ...right];
    } else {
        return [start, end];
    }
}

function perpendicularDistance(point: { x: number; y: number }, lineStart: { x: number; y: number }, lineEnd: { x: number; y: number }) {
    const num = Math.abs(
        (lineEnd.y - lineStart.y) * point.x -
        (lineEnd.x - lineStart.x) * point.y +
        lineEnd.x * lineStart.y -
        lineEnd.y * lineStart.x
    );
    const den = Math.sqrt(
        (lineEnd.y - lineStart.y) ** 2 +
        (lineEnd.x - lineStart.x) ** 2
    );
    return num / den;
}


function simplifyLineSegments(lineSegments: LineSegment[], epsilon:number): LineSegment[][] {
    const lineSegmentsBySketchLineId = new Map<number, LineSegment[]>();

    // Group line segments by sketchLineId
    for (const segment of lineSegments) {
        const key = segment.sketchLineId;
        if (!lineSegmentsBySketchLineId.has(key)) {
            lineSegmentsBySketchLineId.set(key, []);
        }
        lineSegmentsBySketchLineId.get(key)!.push(segment);
    }

    const simplifiedLineSegments: LineSegment[][] = [];

    // Process each group
    lineSegmentsBySketchLineId.forEach((segments: LineSegment[], sketchLineId: number) => {
        const points: Point2D[] = [];

        // Build the polyline from line segments
        for (const segment of segments) {
            const startPoint: Point2D = { x: segment.xStart, y: segment.yStart };
            const endPoint: Point2D = { x: segment.xEnd, y: segment.yEnd };

            // Avoid duplicate consecutive points
            if (
                points.length === 0 ||
                points[points.length - 1].x !== startPoint.x ||
                points[points.length - 1].y !== startPoint.y
            ) {
                points.push(startPoint);
            }

            if (startPoint.x !== endPoint.x || startPoint.y !== endPoint.y) {
                points.push(endPoint);
            }
        }

        // Apply Douglas-Peucker algorithm to simplify points
        const simplifiedPoints = douglasPeucker(points, epsilon);

        // Reconstruct simplified line segments
        const simplifiedSegments: LineSegment[] = [];
        for (let i = 0; i < simplifiedPoints.length - 1; i++) {
            const start = simplifiedPoints[i];
            const end = simplifiedPoints[i + 1];
            const originalSegment = segments[0]; // Assumes uniform properties

            simplifiedSegments.push({
                xStart: start.x,
                yStart: start.y,
                xEnd: end.x,
                yEnd: end.y,
                thickness: originalSegment.thickness,
                color: originalSegment.color,
                sketchLineId: sketchLineId
            });
        }

        simplifiedLineSegments.push(simplifiedSegments);
    }
    );

    return simplifiedLineSegments;
}

function convertLineSegmentsToPoints(lineSegments: LineSegment[][]): Point2D[][] {
    const pointsArray: Point2D[][] = [];

    for (const segmentGroup of lineSegments) {
        const points: Point2D[] = [];

        // Add the start point of the first segment in the group
        if (segmentGroup.length > 0) {
            const firstSegment = segmentGroup[0];
            points.push({ x: firstSegment.xStart, y: firstSegment.yStart });
        }

        // Add the end points of each segment in the group
        for (const segment of segmentGroup) {
            points.push({ x: segment.xEnd, y: segment.yEnd });
        }

        pointsArray.push(points);
    }

    return pointsArray;
}


export const linearizeSketches = (lineSegments: LineSegment[]): Point2D[][] => {
    const epsilon = 0.05;
    const peuckerizedSegments:Point2D[][] = convertLineSegmentsToPoints(simplifyLineSegments(lineSegments, epsilon));
    return peuckerizedSegments;
}

export default Sketch;