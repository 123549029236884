import React from "react";

import { ReactComponent as InfoIcon } from "../NotificationInfoOutline_14px.svg";

import "./CustomTabs.css";

type CustomTabsProps = {
  onClick: (tabNumber: number) => void;
  currentTab: number;
};

const CustomTabs: React.FC<CustomTabsProps> = ({onClick, currentTab}) => {
  return (
    <div className="tabs" role="tablist">
      <div onClick={() => onClick(0)} className={`tab ${currentTab === 0 ? "tab-selected" : ''}`} role="tab" tabIndex={0}>
        <div className="tab-content">
          <div className="fggrbkg">
            <div
              className="tab-text-wrapper"
              tabIndex={0}
            >
              <p className="tab-text">
                Sketch Library
              </p>
            </div>
          </div>
        </div>
      </div>
      <div onClick={() => onClick(1)} className={`tab ${currentTab === 1 ? "tab-selected" : ''}`} role="tab" tabIndex={0}>
          <div className="tab-content">
            <div className="fggrbkg">
            <div
              className="tab-text-wrapper"
              tabIndex={0}
            >
              <p className="tab-text">
                Tableau Agent
              </p>
              <div className="tab-icon">
                <InfoIcon />
              </div>
            </div>
            </div>
          </div>
        </div>
    </div>
  );
};

export default CustomTabs;
