

export class DataPartitionOld
{
    public partitionLabel:string;
    public readonly xColumnName:string;
    public readonly yColumnName:string;
    public readonly data: number[][];
    public /*not readonly*/ simplificationIndices:number[]; // this can be set at runtime if they choose a new epsilon

    constructor(data: any)
    {
        this.partitionLabel =         data.dataDescription.partitionLabel;
        this.xColumnName =            data.dataDescription.jsonOriginalDataColumnNames[0];
        this.yColumnName =            data.dataDescription.jsonOriginalDataColumnNames[1];
        this.data =                   data.dataDescription.jsonOriginalData;
        this.simplificationIndices =  data.simplificationIndices;
    }


}

export class DataPartition
{
    public readonly partitionLabel: string;
    public readonly xColumnName: string;
    public readonly yColumnName: string;
    public readonly xData: Date[];
    public readonly yData: number[];
    public simplificationIndices: number[]; // this can be set at runtime if they choose a new epsilon

    constructor(partitionLabel:string, xColumnName:string, yColumnName:string, xData: Date[], yData:number[], simplificationIndices:number[])
    {
        this.partitionLabel = partitionLabel;
        this.xColumnName = xColumnName;
        this.yColumnName = yColumnName;
        this.xData = xData;
        this.yData = yData;
        this.simplificationIndices = simplificationIndices;
    }
};
