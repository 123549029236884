import React from "react";
import "./CustomDialog.css"

type CustomDialogProps = {
  title?: string;
  open: boolean;
  onRequestClear: () => void;
  onRequestCancel: () => void;
  onRequestSave: () => void;
  children: React.ReactNode;
};

const CustomDialog: React.FC<CustomDialogProps> = ({
  title,
  open,
  onRequestClear,
  onRequestCancel,
  onRequestSave,
  children
}) => {
  return (
    <>
      {open &&
        <div
          className="dialog-floater"
          >
          <dialog className="dialog-wrapper"
            onClose={onRequestClear}>
            {title && <div className="dialog-title">{title}</div> }
            <div className="dialog-content-wrapper">
                {children}
            </div>
            <div className="dialog-footer">
              <div className="dialog-footer-left">
                <button
                  className="light-button"
                  onClick={onRequestClear}
                >
                  Clear
                </button>
              </div>
              <div className="dialog-footer-right">
                <button
                  className="light-button"
                  onClick={onRequestCancel}
                >
                  Cancel
                </button>
                <button className="dark-button"
                  onClick={onRequestSave}
                >
                  Query
                </button>
              </div>
            </div>
          </dialog>
        </div>
      }
    </>
  );
};

export default CustomDialog;
