export type ChatTextType = {
    text: string;
    includeLogo: boolean;
    isUserLogo?: boolean;
    waitForUser?: boolean;
};

export const ChatText: ChatTextType[] = [
  {
    text: "Could you summarize what I sketched and what was discovered?",
    includeLogo: true,
    isUserLogo: true,
  },
  {
    text: 'The line suggests fluctuating "GAI Usage," beginning with a sharp rise that quickly reaches a peak, followed by a decline. After hitting a low point, usage steadily increases, culminating in a strong upward trend at the end. This pattern indicates two main phases: an initial spike and fall, followed by a recovery and final peak. Business units that match this sketched line include Customer Service, Finance, and BDRs.',
    includeLogo: true,
    waitForUser: true,
  },
  {
    text: "What's your suggestion for strategies to increase sensible AI usage that leads to greater customer satisfaction?",
    includeLogo: true,
    isUserLogo: true,
  },
  {
    text: "To increase AI usage that directly boosts customer satisfaction, consider strategies that align AI capabilities with customer-centric outcomes. Here are a few tailored suggestions:",
    includeLogo: true,
  },
  {
    text: "1. Empower Customer Service with Augmented AI: Equip representatives with AI tools that assist in real-time—such as response suggestions, sentiment analysis, and next-best-action insights. This can help reps address customer needs faster and more accurately, especially for complex queries.",
    includeLogo: false,
  },
  {
    text: "2. Implement Predictive Analytics for Anticipative Service: Use AI to predict common customer issues or needs before they arise. For example, analyzing customer purchase patterns, service history, or feedback can allow proactive support, which can prevent problems and improve satisfaction.",
    includeLogo: false,
  },
  {
    text: "3. Develop AI-Based Self-Service Options: Well-designed, AI-powered chatbots or virtual assistants can handle straightforward requests, freeing up customer service reps to focus on more complex issues. Ensure the AI can transfer customers to a live agent seamlessly when needed to avoid frustrating dead ends.",
    includeLogo: false,
  },
  {
    text: "4. Personalize Customer Interactions: Use AI to tailor experiences based on customer profiles and past interactions. Personalized product recommendations, customized support, and proactive check-ins based on prior feedback create a more engaging and relevant experience.",
    includeLogo: false,
  },
  {
    text: "5. Incorporate Customer Feedback into AI Training: Continuously train AI systems on real customer feedback to ensure it evolves with customer expectations. Regularly analyze customer satisfaction scores and feedback on AI-driven interactions to identify improvement areas.",
    includeLogo: false,
  },
  {
    text: "6. Balance Automation with Human Touchpoints: Implement AI where it can add value without compromising personal interactions. For example, use AI to handle initial information gathering and quick resolutions, but have human representatives follow up to ensure satisfaction, particularly after complex or emotional interactions.",
    includeLogo: false,
  },
  {
    text: "7. Monitor and Optimize AI Decisions for Fairness and Transparency: Customers respond positively when they feel they are treated fairly. Make AI processes transparent, and regularly audit AI decision-making, especially in areas like eligibility, approvals, or product recommendations.",
    includeLogo: false,
  },
  {
    text: "8. Provide Education on AI Features: Educate both customers and employees on the AI tools available, emphasizing their benefits and limitations. Clear communication on what AI can and cannot do helps manage expectations, increasing satisfaction with AI-driven services.",
    includeLogo: false,
  },
  {
    text: "9. By integrating these strategies, AI can be positioned as an enabler of better, more personalized customer experiences, ultimately driving satisfaction and loyalty.",
    includeLogo: false,
  },
];
