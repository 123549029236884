import axios from 'axios';
import { Point2D, ShapeSearchReturnStruct, throwIfNull } from './utils';
import { DataPartition } from './DataPartition';
import { parseBinaryData } from './BinaryDecoder';
import { GoogleSheetsManager } from './GoogleSheetsManager';
import { ShapeFilterDefinition } from './ShapeFilterDefinition';
import { linearizeSketches } from './Sketch';

const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
});

export const updateEpsilonIndexAndLocalVsGlobal = async (epsilonIndex: number, isLocal: boolean, measureName: string,
    dataPartitions: DataPartition[],
    setDataPartitions: (dps: DataPartition[]) => void) => {
    const payload = {
        "defaultToleranceIndex": epsilonIndex,
        "useLocallyNormalizedData": isLocal,
        "measureName": measureName
    };

    var response = await api.post('/api/Data/SetCurrentSimplificationDataSet', payload, { headers: { 'Content-Type': 'application/json' } })

    console.log(response);
    if(!response || !response.data)
        return;

    if(response.status === 500) // check that you have loaded data
        return;

    const labelDict:{[key:string]: number[]} = {};
    response.data.forEach((obj: any) => {
        labelDict[obj.partitionName] = obj.simplificationIndices;
    });

    const newPartitions:DataPartition[] = dataPartitions.map(dp => {
        const newDp = {...dp};
        newDp.simplificationIndices = labelDict[newDp.partitionLabel];
        return newDp;
    });
    
    setDataPartitions(newPartitions);
    return;
}

export const loadDataset = async (datasetCodeName: string,
                                  useLocallyNormalizedData:boolean,
                                  tolerances:number[],
                                  toleranceIndex:number,
                                  measureName:string,
                                  setDataPartitions:(value:DataPartition[])=>void) => {

    // hard code for now
    let xColumnName = null
    let yColumnName = null
    switch(datasetCodeName)
    {
        case 'babynames':
            xColumnName = "Date";
            yColumnName = measureName;
            break;
    }
    throwIfNull(xColumnName);
    throwIfNull(yColumnName);

    const payload = {
        "dataKey": `${datasetCodeName}`,
        "xColumnName": `${xColumnName}`,
        "yColumnName": `${yColumnName}`,
        "tolerances": tolerances,
        "defaultToleranceIndex": toleranceIndex,
        "useLocallyNormalizedData": useLocallyNormalizedData
    };
    var response = await api.post('/api/Data/SetData', payload, { headers: { 'Content-Type': 'application/json' } })

    console.log(response);

    const partitions:DataPartition[] = parseBinaryData(response.data);
    console.log(partitions);
    setDataPartitions(partitions);
    return;
};

export const handleShapeQueries = async (
    shapeDefinitions: Map<string, ShapeFilterDefinition>,
    setShapeSearchResults: (value: Map<string, ShapeSearchReturnStruct[]>) => void,
    dataPartitions: DataPartition[],
    dateRange: { startDate: Date; currentDate: Date; endDate: Date },
    valueRange: { min: number; max: number }
) => {
    if (dataPartitions.length === 0) {
        alert('No data partitions. Select a data source and try again.');
        return;
    }

    const results = new Map<string, ShapeSearchReturnStruct[]>();

    const keys: string[] = Array.from(shapeDefinitions.keys());
    for (let i: number = 0; i < keys.length; i++) {
        const value = shapeDefinitions.get(keys[i]);
        if (value) {
            const shapes = linearizeSketches(value.GetLineSegments());
            if (shapes.length > 0) {
                const result = await doShapeQuery(shapes, keys[i]);
                results.set(keys[i], result);
            }
        }
    }

    handleShapeSearchResults(results, setShapeSearchResults, dataPartitions);
};

export const handleShapeQuery = async (points: Point2D[][], measureName: string, setShapeSearchResults: (value: Map<string, ShapeSearchReturnStruct[]>) => void, dataPartitions: DataPartition[], dateRange: { startDate: Date, currentDate: Date, endDate: Date }, valueRange: { min: number, max: number }) => {
    const results = await doShapeQuery(points, measureName);
    handleShapeSearchResults(new Map<string, ShapeSearchReturnStruct[]>([[measureName, results]]), setShapeSearchResults, dataPartitions)
}

const doShapeQuery = async(points:Point2D[][], measureName:string, resolve?: (value: void | PromiseLike<void>) => void) =>
{
    const payload = {
        "points":points,
        "measureName": measureName
    };

    var response = await api.post('/api/Data/DoShapeSearch', payload, { headers: { 'Content-Type': 'application/json' } })
    console.log(response);

    const shapeQueryResult:ShapeSearchReturnStruct[] = response.data as ShapeSearchReturnStruct[];
    resolve?.();
    return shapeQueryResult;
};

const handleShapeSearchResults = async (results: Map<string, ShapeSearchReturnStruct[]>, setShapeSearchResults:(value: Map<string,ShapeSearchReturnStruct[]>) => void, dataPartitions: DataPartition[]) => {
    const shapeQueryResult = results.get("GAI Usage (minutes)");
    if (shapeQueryResult) {
        const filterValues: string[] = shapeQueryResult.map(cd => cd.partitionName);
        
        let linearizationValues: (number|string)[][] = [];
        if (filterValues.length > 0) {
            for (const filterValueKey in filterValues) {
                const filterValue = filterValues[filterValueKey];
                const partition = dataPartitions.find(cd => cd.partitionLabel === filterValue);
                if (partition) {
                    //const simplificationIndices = partition.simplificationIndices;
                    const score = shapeQueryResult.find(data => data.partitionName === filterValue)?.score ?? 0;
                    //let lastDate: Date | undefined = undefined;
                    //let lastCount: number | undefined = undefined;
                    linearizationValues.push([filterValue, Date(), score]);

                    // simplificationIndices?.forEach(index => {
                    //     if (index < partition.xData.length) {
                    //         const date:  Date =  partition.xData[index];
                    //         // const count: number =  partition.yData[index] as number;

                    //         // // add data points in between the given simplification indices to generate a linear line instead of dots
                    //         // if (lastDate && lastCount) {
                    //         //     // (x1,y1)(x2,y2)
                    //         //     // slope = (y2-y1)/(x2-x1)
                    //         //     // yIntercept = slope*(x1)-(y1)
                    //         //     // equation = slope*X-yIntercept
                    //         //     const slope = (lastCount - count) / (lastDate - date);
                    //         //     const yIntercept = slope * lastDate - lastCount;
                    //         //     for (let i = lastDate + 1; i < date; i++) {
                    //         //         const newCount = slope * i - yIntercept;
                    //         //         linearizationValues.push([filterValue, `${i} Q1`, newCount, score]);
                    //         //     }
                    //         // }
                    //         linearizationValues.push([filterValue, date.toString(), score]);
                    //         lastDate = date;
                    //         //lastCount = count;
                    //     }
                    //});
                }
            };
            await new GoogleSheetsManager().updateSheet(linearizationValues);
        }
    }
    setShapeSearchResults(new Map(results));
}
