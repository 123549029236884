import React, { useEffect, useState, useRef, useContext } from "react";
import {
  Dashboard,
  Parameter,
  TableauEventType,
  Workbook,
} from "@tableau/embedding-api";
import { AppContext } from "./AppContext";
import { ShapeSearchReturnStruct, throwIfNull } from "./utils";

declare global {
  namespace JSX {
    interface IntrinsicElements {
      "tableau-viz": any;
    }
  }
}

type TableauVizComponentProps = {
  url: string;
  filterField: string;
  sheetName: string;
};

const TableauVizComponent: React.FC<TableauVizComponentProps> = ({
  url,
  filterField,
  sheetName,
}) => {
  const [vizIsLoaded, setVizIsLoaded] = useState(false);

  const vizRef = useRef<any>();

  useEffect(() => {
    if (vizRef.current) {
      vizRef.current.addEventListener(TableauEventType.FirstInteractive, () => {
        console.log('Viz is interactive!');
        setVizIsLoaded(true);
      });

      vizRef.current.addEventListener(
        TableauEventType.VizLoadError,
        (error: any) => {
          console.error("Viz load error");
        }
      );
    }
  }, []);

  const context = useContext(AppContext);

  const shapeSearchResults = context?.shapeSearchResults;
  const setShapeSearchResults = context?.setShapeSearchResults;
  const listOfMeasures = context?.listOfMeasures;

  function getParametersForSheet(sheetName: string): string[] {
    const parameters: string[] = [];
  
    for (const measure of throwIfNull(listOfMeasures)) {
      const param = measure.parameter.get(sheetName);
      if (param) {
        parameters.push(param);
      }
    }
  
    return parameters;
  }
  
  useEffect(() => {
    const updateViz = async () => {
      // should only refresh viz if there's new query results
      if (vizRef && vizIsLoaded && vizRef.current && shapeSearchResults && shapeSearchResults.size > 0 && setShapeSearchResults && shapeSearchResults.size > 0) {
        let workbook: Workbook = vizRef.current.workbook;
        ///const sheet = (workbook.activeSheet as Dashboard).worksheets.find((ws) => ws.name === sheetName);
        const sheet = (workbook.activeSheet as Dashboard).worksheets[0];
        //if (sheet) {        
        let promises: Promise<string>[] = [];
        shapeSearchResults.forEach(async (value: ShapeSearchReturnStruct[], key: string) => {
          promises.push(new Promise<string>((resolve, reject) => {
            if (value) {
              const filterValues: string = "," + value.map(cd => cd.partitionName).join(",") + ",";
              //sheet.getDataSourcesAsync().then(sheetResponse => {
              //sheetResponse[0].refreshAsync().then(() => {
              const parameterMap = listOfMeasures?.find(measureObj => measureObj.measureName === key)?.parameter;
              if (parameterMap) {
                const parameter:string = throwIfNull(parameterMap.get(sheet.name))
                workbook.changeParameterValueAsync(parameter, filterValues).then(() => {
                  resolve(parameter);
                });
              } else {
                console.error(`unable to find parameter to update for the measure ${key}`);
                reject(parameterMap);
              }
              //});
              //});
            } else {
              reject();
            }
          }));
        });

        const updatedParameters = await Promise.all(promises);

        const allNamesString = "ALLDATA";

        // if parameters that were not set by this query have a non-null value, reset them to an invalid value
        workbook.getParametersAsync().then((parameters: Parameter[]) => {
          parameters.forEach(parameter => {
            const parametersOnThisSheet = getParametersForSheet((workbook.activeSheet as Dashboard).worksheets[0].name);
            if (updatedParameters.indexOf(parameter.name) === -1
              && parameter.currentValue.value !== allNamesString
              && parametersOnThisSheet.includes(parameter.name)) {
              
                console.log(`resetting the parameter ${parameter}`);
              workbook.changeParameterValueAsync(parameter.name, allNamesString);
            }
          })
        });
        //}    
      }
    }
    updateViz();
  }, [shapeSearchResults, getParametersForSheet, listOfMeasures]);

  return (
    <div className="tableau-viz-component">
      <tableau-viz
        touch-optimize
        ref={vizRef}
        id="tableau-viz"
        src={url}
        width={"100%"}
      />
    </div>
  )
};

export default TableauVizComponent;
