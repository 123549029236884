import React, { createContext, useState, ReactNode, useEffect } from 'react';
import { ShapeFilterDefinition } from './ShapeFilterDefinition';
//import { DataTable, GetToyDataTable } from './DataTable';
import { handleShapeQueries, loadDataset, updateEpsilonIndexAndLocalVsGlobal } from './ApiService';
import { DataPartition } from './DataPartition';
import { ShapeSearchReturnStruct } from './utils';

export type MeasureType = {measureName: string, parameter: Map<string,string>, color: string};

export type ShapeType = {annotationShapes: Map<string,ShapeFilterDefinition>, measureShapes: Map<string,ShapeFilterDefinition>};

interface AppContextProps {
  forecastingEnabled: boolean;
  setForecastingEnabled: (value: boolean) => void;

  searchByPercentage: boolean;
  setSearchByPercentage: (value: boolean) => void;
  
  epsilons: number[];
  epsilonIndex: number;
  setEpsilonIndex: (value: number) => void;

  valueRange: {min: number, max: number};
  dateRange: {startDate: Date, currentDate: Date, endDate: Date};

  canvasWidth: number;
  canvasHeight: number;
  
  angleError: number;
  setAngleError: (value: number) => void;
  lineError: number;
  setLineError: (value: number) => void;
  
  datasource: string;
  loadNewDataset: (value: string) => void;
  queryShape: (index: number) => void;
  dataPartitions:DataPartition[];
  setDataPartitions:(value:DataPartition[]) => void;
  
  shapeSearchResults:Map<string,ShapeSearchReturnStruct[]>;
  setShapeSearchResults:(value: Map<string,ShapeSearchReturnStruct[]>) => void;

  shapeFilterLibrary: ShapeType[];
  addShapeFilterToLibrary: () =>  number; // returns index where it was stored
  removeShapeFilterFromLibrary: (shapeFilterIndex: number) => void;
  updateShapeInLibrary:(updatedShape:ShapeFilterDefinition, index:number, measureName: string) => void;
  updateLibraryForClearButton:(index: number) => void;
  updateAnnotationInLibrary:(updatedShape:ShapeFilterDefinition, index:number, color: string) => void;

  currentShapeIndex: number;
  setCurrentShapeIndex: (value: number) => void;

  listOfMeasures: MeasureType[];
  defaultMeasure: MeasureType;
  currentMeasure: string;
  measuresOfInterest: string[];
  currentColor: string;
  setCurrentColor: (value: string) => void;
  updateCurrentMeasure: (value: string) => void;

  //dataTable: DataTable;
  //setDataTable: (value:DataTable) => void;
}

export const AppContext = createContext<AppContextProps | undefined>(undefined);

export const AppProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const kEpsilonValues:number[] =  [0.01, .015, .02, 0.03, 0.05, 0.075, 0.1, 0.15]; //Array(20).fill(0).map((_, i) => Math.pow(i*0.05,4));
  const kDefaultEpsilonIndex:number = Math.floor((kEpsilonValues.length)/2); //indexing into kEpsilonValues
	
	const valueRange= {min:0, max:1}; //{dataTable.GetValueRange(dataMeasure)}

  const canvasWidth = 900;
  const canvasHeight = 480;

  //TBD - MAGIC NUMBERS - CHANGE WITH NEW DATA SET
	const dateRange = {
    startDate: new Date(2024, 1, 1), //new Date(Math.min(...yearsFromData).toString()),
    currentDate: new Date(2024, 4, 20), //new Date(Math.max(...yearsFromData).toString()),
    endDate: new Date(2024, 5, 2)
  }; //{dataTable.GetDateRange()};

  const [forecastingEnabled, setForecastingEnabled] = useState(false);
  const [searchByPercentage, setSearchByPercentage] = useState(true);
  const [epsilonIndex, setEpsilonIndex] = useState(kDefaultEpsilonIndex);
  const [angleError, setAngleError] = useState(10);
  const [lineError, setLineError] = useState(0.3);
  const [datasource, setDatasource] = useState('');
  const [shapeFilterLibrary, setShapeFilterLibrary] = useState<ShapeType[]>([]);
  //const [dataTable, setDataTable] = useState<DataTable>(GetToyDataTable(10));
  const [dataPartitions, setDataPartitions] = useState<DataPartition[]>([]);
  const [shapeSearchResults, setShapeSearchResults] = useState<Map<string,ShapeSearchReturnStruct[]>>(new Map<string, ShapeSearchReturnStruct[]>());
  const [currentShapeIndex, setCurrentShapeIndex] = useState<number>(0);

  const listOfMeasures: MeasureType[] = [
    { measureName: "GAI Usage (minutes)",
      parameter: new Map([
                          ["1. Daily Generative AI Usage", "Names_Param_GAI_Usage"],
                          ["2. GAI and CSAT","Names_Param_GAI_Usage_2"],
                          ["3. Cohort Analysis",    "Names_Param_GAI_Usage_2"]
                        ]),
      color: "rgb(78,121,167)"
    },
    {measureName: "Customer CSAT",
      parameter: new Map([
                          ["1. Daily Generative AI Usage", "Names_Param_Customer_Issues"],
                          ["2. GAI and CSAT","Names_Param_Customer_Issues_2"],
                          ["3. Cohort Analysis",    "Names_Param_Customer_Issues_2"]
                        ]),
      color: 'rgb(242,142,43)'}
    ];
  
  const defaultMeasure = listOfMeasures[0];
  const [currentMeasure, setCurrentMeasure] = useState<string>(defaultMeasure.measureName);
  const [currentColor, setCurrentColor] = useState<string>(defaultMeasure.color);

  const [measuresOfInterest, ] = useState<string[]>([listOfMeasures[0].measureName, listOfMeasures[1].measureName]);
  
  // useEffect(() => {
  //   dataTable.Peuckerize(dataMeasure, epsilonIndex);
  //   dataTable.CompareToShapeFilter(shapeFilterLibrary[0]);
  // }, [dataMeasure, epsilonIndex]);

  // useEffect(() => {
  //   shapeFilterLibrary[0].CalculateMetrics();
  //   dataTable.CompareToShapeFilter(shapeFilterLibrary[0]);
  // }, [searchByPercentage, angleError, lineError, shapeFilterLibrary]);

  useEffect(() => {
    if (shapeFilterLibrary.length > 0) {
      let shape = shapeFilterLibrary[0].measureShapes.get(currentMeasure);
      if (shape) {
        shape.angleError = angleError;
        shapeFilterLibrary[0].measureShapes.set(currentMeasure, shape);
      }
    }
  }, [angleError]);

  useEffect(() => {
    if (shapeFilterLibrary.length > 0 && shapeFilterLibrary[0].measureShapes.has(currentMeasure)) {
      let shape = shapeFilterLibrary[0].measureShapes.get(currentMeasure);
      if (shape) {
        shape.lineError = lineError;
        shapeFilterLibrary[0].measureShapes.set(currentMeasure, shape);
      }
    }
  }, [lineError]);

  useEffect(() => {
    updateEpsilonIndexAndLocalVsGlobal(epsilonIndex, searchByPercentage, currentMeasure, dataPartitions, setDataPartitions);
  }, [searchByPercentage, epsilonIndex]);

  useEffect(() => {
    updateColorFromMeasure(currentMeasure);
  }, [currentMeasure]);

  function addShapeFilterToLibrary(): number {
      const newMeasure = defaultMeasure.measureName;
      const newShape = new ShapeFilterDefinition(25, 0.35);
      updateCurrentMeasure(newMeasure);
      setShapeFilterLibrary(
        [{ annotationShapes: new Map<string, ShapeFilterDefinition>(), measureShapes: new Map<string, ShapeFilterDefinition>([[newMeasure, newShape]])},
          ...shapeFilterLibrary
        ]);
    return 0;
  }

  function updateCurrentMeasure(measureName: string): void {
    setCurrentMeasure(measureName);
    updateColorFromMeasure(measureName);
  }

  function updateColorFromMeasure(measureName: string): void {
    const newColor = listOfMeasures.find(measure => measure.measureName === measureName)?.color;
    if (newColor) {
      setCurrentColor(newColor);
    }
  }

  function removeShapeFilterFromLibrary(shapeFilterIndexToRemove: number): void {
    setShapeFilterLibrary(shapeFilterLibrary.filter((_, i) => i !== shapeFilterIndexToRemove));
  }

  function updateShapeInLibrary(updatedShape:ShapeFilterDefinition, index:number, measureName: string): void {
    const newShapeArray = [...shapeFilterLibrary];
    newShapeArray[index].measureShapes.set(measureName, updatedShape);
    setShapeFilterLibrary(newShapeArray);
  }

  function updateLibraryForClearButton(index: number) {
    const newShapeArray = [...shapeFilterLibrary];
    newShapeArray[index] = {annotationShapes: new Map(), measureShapes: new Map()};
    setShapeFilterLibrary(newShapeArray);
  }

  function updateAnnotationInLibrary(updatedShape:ShapeFilterDefinition, index:number, annotationColor: string): void {
    const newShapeArray = [...shapeFilterLibrary];
    if (annotationColor) {
      newShapeArray[index].annotationShapes.set(annotationColor, updatedShape);
    }
    setShapeFilterLibrary(newShapeArray);
  }


  function loadNewDataset(datasetCodeName: string): void {
    loadDataset(datasetCodeName, searchByPercentage, kEpsilonValues, epsilonIndex, measuresOfInterest[0], setDataPartitions).then(() => {
      // parse it and store it
      setDatasource(datasetCodeName);

      // load the second measure, but don't handle the returns (null function).
      loadDataset(datasetCodeName, searchByPercentage, kEpsilonValues, epsilonIndex, measuresOfInterest[1], value => { });
    });
  }

  function queryShape(index: number) {
    handleShapeQueries(shapeFilterLibrary[index].measureShapes, setShapeSearchResults, dataPartitions, dateRange, valueRange);
  }

  return (
    <AppContext.Provider
      value={{
        forecastingEnabled,
        setForecastingEnabled,
        searchByPercentage,
        setSearchByPercentage,
        epsilons:kEpsilonValues,
        epsilonIndex,
        setEpsilonIndex,
        valueRange,
        dateRange,
        canvasWidth,
        canvasHeight,
        angleError,
        setAngleError,
        lineError,
        setLineError,
        datasource,
        loadNewDataset,
        queryShape,
        dataPartitions,
        setDataPartitions,
        shapeSearchResults,
        setShapeSearchResults,
        shapeFilterLibrary,
        addShapeFilterToLibrary,
        removeShapeFilterFromLibrary,
        updateShapeInLibrary,
        updateLibraryForClearButton,
        updateAnnotationInLibrary,
        currentShapeIndex,
        setCurrentShapeIndex,
        listOfMeasures,
        currentMeasure,
        measuresOfInterest,
        defaultMeasure,
        updateCurrentMeasure,
        currentColor,
        setCurrentColor,

        //dataTable,
        //setDataTable
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
