import { DataPartition } from "./DataPartition";
const msgpack = require('msgpack-lite');

export function parseBinaryData(base64String: string): DataPartition[] {
    
    function base64ToUint8Array(base64: string): Uint8Array {
        const binaryString: string = atob(base64);
        const len: number = binaryString.length;
        const bytes: Uint8Array = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        return bytes;
    }
    
    const uint8Array: Uint8Array = base64ToUint8Array(base64String);
    
    const deserializedArray: any[] = msgpack.decode(uint8Array);
    
    const charts: DataPartition[] = deserializedArray.map((data: any) => {
        const xDataDates: Date[] = data.xData.map((timestamp: number) => new Date(timestamp));
        return new DataPartition(
            data.partitionLabel,
            data.xColumnName,
            data.yColumnName,
            xDataDates,
            data.yData,
            data.simplificationIndices
        );
    });
    
    return charts;
}
